// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:500px) {
    .navbar{
        display:none
    }
    .welcome{
        font-size: 12pt;
    }

    .pimg1, .pimg2, .pimg3{
        background-attachment: inherit;
    }
    .experience{
        display: flex;
    }
    .summary-text{
        display: flex;
        justify-content: center;
        padding-left: 0;
    }
    .text-background{
        display: flex;
        justify-content: center;
        margin-left: 30px;
    }
    .project-preview{
        height: 150px;
        width: auto;
    }
    section h2{
        margin: 0px
    }
    .story{
        margin: 0px;
    }
    .contactInfo{
        margin-left: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Mobile.css"],"names":[],"mappings":"AAAA;IACI;QACI;IACJ;IACA;QACI,eAAe;IACnB;;IAEA;QACI,8BAA8B;IAClC;IACA;QACI,aAAa;IACjB;IACA;QACI,aAAa;QACb,uBAAuB;QACvB,eAAe;IACnB;IACA;QACI,aAAa;QACb,uBAAuB;QACvB,iBAAiB;IACrB;IACA;QACI,aAAa;QACb,WAAW;IACf;IACA;QACI;IACJ;IACA;QACI,WAAW;IACf;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media only screen and (max-width:500px) {\n    .navbar{\n        display:none\n    }\n    .welcome{\n        font-size: 12pt;\n    }\n\n    .pimg1, .pimg2, .pimg3{\n        background-attachment: inherit;\n    }\n    .experience{\n        display: flex;\n    }\n    .summary-text{\n        display: flex;\n        justify-content: center;\n        padding-left: 0;\n    }\n    .text-background{\n        display: flex;\n        justify-content: center;\n        margin-left: 30px;\n    }\n    .project-preview{\n        height: 150px;\n        width: auto;\n    }\n    section h2{\n        margin: 0px\n    }\n    .story{\n        margin: 0px;\n    }\n    .contactInfo{\n        margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
